@import '../../styles/mixins';

.bracket-container {

}

.house {
  display: flex;
  flex-direction: row;
  margin: 65px 0;
  @media #{$mobile} {
    flex-direction: column;
    &:first-child {
      margin-top: 0px;
    }
  }
  .house-name {
    font-family: 'game_of_thronesregular', serif;
    color: #29455a;
  }
  .house-info {
    width: 30%;
    margin-right: 45px;
    @media #{$mobile} {
      width: 100%;
    }
  }
  .house-members {
    display: flex;
    flex-direction: column;
    flex: 1;
    > div:not(:first-child) {
      margin-top: 25px !important;
    }
  }
}

.edit-button {
  margin-left: 10px !important;
}

.character-wrapper {
  display: flex;
  .icon {
    width: 35px;
  }
}
