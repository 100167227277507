body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.countdown-container {
  display: flex;
  height: 100%;
  color: #fff;
  justify-content: center; }
  @media (max-width: 450px) {
    .countdown-container {
      flex-grow: 1; } }
  .countdown-container .countdown {
    font-family: 'Nova Mono', monospace;
    align-self: center; }

.drawer-bottom {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 15px; }
  .drawer-bottom .disclaimer {
    color: #fff;
    font-size: 12px; }
  .drawer-bottom a {
    text-align: center;
    text-decoration: none;
    color: #557087; }

.pool-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row; }
  @media (max-width: 450px) {
    .pool-panel-wrapper {
      flex-direction: column-reverse; } }

.pool-players-list {
  flex-basis: 80%; }
  .pool-players-list.full-width {
    flex-basis: 100%; }
    @media (max-width: 450px) {
      .pool-players-list.full-width {
        overflow-x: scroll; } }

.pool-actions {
  flex: 1 1;
  justify-content: flex-end;
  display: flex; }
  @media (max-width: 450px) {
    .pool-actions {
      flex: inherit;
      display: inline-flex;
      align-self: flex-end; } }

.pool-admin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  @media (max-width: 450px) {
    .pool-admin {
      flex-direction: column; } }
  .pool-admin .join-pool, .pool-admin .create-pool {
    display: flex;
    align-items: center; }

.player-content {
  display: flex;
  justify-content: center; }
  .player-content .avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0; }
    @media (max-width: 450px) {
      .player-content .avatar-wrapper {
        max-width: 110px; } }
    .player-content .avatar-wrapper span {
      overflow-wrap: break-word;
      margin-top: 10px;
      width: 100%; }

@font-face {
  font-family: 'game_of_thronesregular';
  src: url(/static/media/game_of_thrones-webfont.7f2c549a.woff2) format("woff2"), url(/static/media/game_of_thrones-webfont.90173f9d.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: 'Alegreya Sans', sans-serif; }
  body a {
    color: #29455a; }

.toolbar {
  min-height: 58px; }

.app-container {
  padding: 40px;
  flex-grow: 1;
  background: url("https://d3hlw4rqvr3ro4.cloudfront.net/mountains2.jpg") center;
  background-size: cover; }

.content-container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); }

.viewport-container {
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column; }

.App {
  display: flex; }
  @media (max-width: 450px) {
    .App {
      display: block; } }

header a {
  color: #fff;
  text-decoration: none; }

.house {
  display: flex;
  flex-direction: row;
  margin: 65px 0; }
  @media (max-width: 450px) {
    .house {
      flex-direction: column; }
      .house:first-child {
        margin-top: 0px; } }
  .house .house-name {
    font-family: 'game_of_thronesregular', serif;
    color: #29455a; }
  .house .house-info {
    width: 30%;
    margin-right: 45px; }
    @media (max-width: 450px) {
      .house .house-info {
        width: 100%; } }
  .house .house-members {
    display: flex;
    flex-direction: column;
    flex: 1 1; }
    .house .house-members > div:not(:first-child) {
      margin-top: 25px !important; }

.edit-button {
  margin-left: 10px !important; }

.character-wrapper {
  display: flex; }
  .character-wrapper .icon {
    width: 35px; }

.loading-screen {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.loading-screen:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

.compare-bracket {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px; }
  .compare-bracket .character {
    margin: 20px 0px; }

