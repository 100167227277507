@import '../../styles/mixins';

.pool-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  @media #{$mobile} {
    flex-direction: column-reverse;
  }
}

.pool-players-list {
  flex-basis: 80%;
  &.full-width {
    flex-basis: 100%;
    @media #{$mobile} {
      overflow-x: scroll;
    }
  }
}

.pool-actions {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  @media #{$mobile} {
    flex: inherit;
    display: inline-flex;
    align-self: flex-end;
  }
}

.pool-admin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media #{$mobile} {
    flex-direction: column;
  }
  .join-pool, .create-pool {
    display: flex;
    align-items: center;
  }
}
.player-content {
  display: flex;
  justify-content: center;
  .avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    @media #{$mobile} {
      max-width: 110px;
    }
    span {
      overflow-wrap: break-word;
      margin-top: 10px;
      width: 100%;
    }
  }
}
