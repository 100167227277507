@import 'styles/mixins';

@font-face {
  font-family: 'game_of_thronesregular';
  src: url('./fonts/game_of_thrones-webfont.woff2') format('woff2'),
  url('./fonts/game_of_thrones-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Alegreya Sans', sans-serif;
  a {
    color: #29455a;
  }
}

.toolbar {
 min-height: 58px;
}

.app-container {
  padding: 40px;
  flex-grow: 1;
  background: url('https://d3hlw4rqvr3ro4.cloudfront.net/mountains2.jpg') center;
  background-size: cover;
}

.content-container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
}

.viewport-container {
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  @media #{$mobile} {
    display: block;
  }
}

header {
  a {
    color: #fff;
    text-decoration: none;
  }
}